<template>
  <div class="top">
    <div>美术专业基础教学质量检测<br />
      考务管理系统{{ name }}</div>
  </div>
</template>

<script>
// import { ref } from 'vue'
// import { useRouter } from 'vue-router'
export default {
  props: {
    name: {
      type: String,
      default: ''
    }

  },
  emits: ['callback'],
}
</script>
<style  scoped>
.top {
  background: #43ABF2;
  color: #fff;
  font-size: 2em;
  text-align: center;
}

.top div {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>