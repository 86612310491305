export function getQueryString(name) {
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if(r != null) {
        return  unescape(r[2]);
    } else {
        return null
    }
  }
  
  export const getLocal = (name) => {
    return localStorage.getItem(name)
  }
  
  export const setLocal = (name, value) => {
    localStorage.setItem(name, value)
  }

  export const getLocalUser = (name) => {
    return JSON.parse(localStorage.getItem(name));
  }

  /**
   * 照片前缀
   */
  export const prefix = (url) => {
    if (url && url.startsWith('http')) {
      return url
    } else {
      url = `${process.env.VUE_APP_CLOUD_HTTP}/${url}`
      return url
    }
  }

  export const getConfig=(name)=>{
    let data =  JSON.parse(localStorage.getItem('config'));
    var newdata=[];  //定义一个空的数组
    if(typeof(data)=='object' && data!=null){
      console.log('foreach');
      data.forEach(function(val){
          if(val.key==name)
          newdata=val;
      });
    }
   
    return newdata;
  }

 